<!--
 * @Author: 月魂
 * @Date: 2021-04-12 10:22:22
 * @LastEditTime: 2021-06-24 16:46:49
 * @LastEditors: 月魂
 * @Description: 
 * @FilePath: \stoms-mp\src\views\pay.vue
-->
<template>
  <div>
     <van-nav-bar
      title="订单支付"
      fixed
      placeholder
    />
    <div class="title">支付方式</div>
    <div class="cont">
      <img src="../assets/pay.svg" alt="">
    </div>
    <div class="text">微信支付</div>
    <van-goods-action safe-area-inset-bottom>
       <van-goods-action-button
        type="danger"
        text="立即支付"
        @click="handleBuy"
      />
    </van-goods-action>
  </div>
</template>

<script>
import Vue from 'vue';
import { NavBar, GoodsActionButton, GoodsAction, Toast } from 'vant';
Vue.use(NavBar).use(GoodsActionButton).use(GoodsAction).use(Toast);
export default {
  name: 'Pay',
  methods: {
    handleBuy() {
      const that = this;
      this.$store.dispatch('card/buyCard', this.$route.params.id).then(res => {
        if (res.code !== 0) return
        const { appId, nonceStr, pkg, paySign, signType, timeStamp } = res.data;
        // eslint-disable-next-line no-undef
        WeixinJSBridge.invoke(
          'getBrandWCPayRequest', {
            appId,   //公众号名称，由商户传入
            timeStamp,        //时间戳，自1970年以来的秒数
            nonceStr, //随机串
            "package": pkg,
            signType,         //微信签名方式：
            paySign //微信签名
          },
          function(res){
            if(res.err_msg == "get_brand_wcpay_request:ok"){
              Toast.success('支付成功');
              that.$router.replace('/cardBag')
              // 使用以上方式判断前端返回,微信团队郑重提示：
              //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            } else {
              that.$router.replace(`/order/${this.$route.params.id}`)
            }
          });
      })
    }
  }
}
</script>

<style lang="less" scoped>
.title {
  padding: 10px;
  font-weight: 500;
  color: #333;
  font-size: 16px;
}
.cont {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  img {
    display: block;
    width: 120px;
  }
}
.text {
  text-align: center;
}
</style>
